import React from "react"
import Layout from "../../layouts/default"
import Seo from "../../components/seo"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import PageTitle from "../../components/page-title"
import Stack from "../../components/stack"
import TeaserDigital from "../../content/shared/teaser-digital"

const Page = () => {
  return (
    <Layout backdrop="404">
      <Seo
        title="Danke für dein Feedback"
        description="Lust auf Lyrik? Unsere interaktiven Kurse ermöglichen Annäherungen an Hölderlins Sprachkunstwerke. Im Mittelpunkt steht jeweils ein Gedicht von Hölderlin, das aus verschiedenen Perspektiven erschlossen wird."
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Für Schulen",
              link: "/fuer-schulen",
            },
            {
              title: "Digitale Kurse",
              link: "/kurse",
            },
            {
              title: "Feedback",
              link: "/kurse/feedback-erhalten",
            },
          ]}
        />
        <PageTitle>Vielen Dank für dein Feedback! Du bist spitze!</PageTitle>
        <Paragraph dropcap={true}>
          Hast du Lust noch mehr von unseren digitalen Angeboten kennenzulernen?
          Unsere Dauer- und Sonderausstellungen kann man auch digital erkunden.
          Unter unseren Workshop-Tutorials findest du Anleitungen und
          Inspirationen zum Schreiben, Basteln, Rappen und Bildergeschichten
          entwickeln. Du kannst unseren Turmgespräche-Podcasts lauschen, in
          unserem Turm-TV streamen oder in der Jahreszeiten-Gedicht-Maschine
          einen Remix aus Hölderlins Frühlings-, Sommer-, Herbst- und
          Wintergedichten zusammenschustern.
        </Paragraph>
        <TeaserDigital />
      </Stack>
    </Layout>
  )
}

export default Page
